export enum CarbonEnvName {
  ALPHA = "alpha",
  PROD = "prod",
  DEV = "dev",
}

export const CarbonEnvs = {
  [CarbonEnvName.ALPHA]: {
    name: CarbonEnvName.ALPHA,
    subdomain: CarbonEnvName.ALPHA,
    domain: "carbonhealth.com",
    protocol: "https",
  },
  [CarbonEnvName.PROD]: {
    name: CarbonEnvName.PROD,
    subdomain: null,
    domain: "carbonhealth.com",
    protocol: "https",
  },
  [CarbonEnvName.DEV]: {
    name: CarbonEnvName.DEV,
    subdomain: null,
    domain: "localhost:5002",
    protocol: "http",
  },
} as const;
