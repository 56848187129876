import {LocalStorageKey, getFromLocalStorageRaw, setInLocalStorageRaw} from "../localStorage";

// currentUserId is saved as _raw_ strings in local storage because we need to coordinate with
// the patient app to make login state seamless.
//
// The patient app does not save this key as a JSON.stringify()'d value, so we can't use
// the typesafe getters & setters here.
export const setCurrentUserId = (id: string) =>
  setInLocalStorageRaw(LocalStorageKey.CurrentUserId, id);

export const getCurrentUserId = () => getFromLocalStorageRaw(LocalStorageKey.CurrentUserId);
