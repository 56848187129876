export enum FeatureFlag {
  VAX_LOC_IDS = "VAX_LOC_IDS",
  GROWTH_DISCOVERY_APPT_REASON_PAGE_AVAILABILITY_ENABLED = "GROWTH_DISCOVERY_APPT_REASON_PAGE_AVAILABILITY_ENABLED",
  GROWTH_DISCOVERY_SEARCH_AVAILABILITY_ENABLED = "GROWTH_DISCOVERY_SEARCH_AVAILABILITY_ENABLED",
  GROWTH_CACHED_SLOTS_ENABLED = "GROWTH_CACHED_SLOTS_ENABLED",
  CARE_DISCOVERY_TOPICS_SEARCH_ENABLED = "CARE_DISCOVERY_TOPICS_SEARCH_ENABLED",
  GROWTH_WEBSITE_BANNER = "GROWTH_WEBSITE_BANNER",
  GROWTH_VPC_DESIGN_UPDATE_1 = "GROWTH_VPC_DESIGN_UPDATE_1",
  PASSWORDLESS_LOGIN_ENABLED_EMAILS = "PASSWORDLESS_LOGIN_ENABLED_EMAILS",
}
