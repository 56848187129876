/// <reference types="@welldone-software/why-did-you-render" />
import React from "react";

import {PublicEnv} from "./src/utils/getEnvVar";

if (process.env.NODE_ENV === "development") {
  if (typeof window !== "undefined") {
    const whyDidYouRender = require("@welldone-software/why-did-you-render");
    whyDidYouRender(React, {
      trackAllPureComponents: PublicEnv.WDYR_TRACK_ALL_PURE_COMPONENTS || false,
      collapseGroups: true,
      logOnDifferentValues: true,
    });
  }
}
