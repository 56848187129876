import memoizee from "memoizee";
import queryString from "query-string-for-all";

import {cachedSlotsAPIRootUrl} from "./constants";
import {ApptSlotsWorkerParams} from "./types";

export const getApptCachedUrl = memoizee(
  (args: ApptSlotsWorkerParams): string =>
    queryString.stringifyUrl({
      url: process.env.CACHED_SLOTS_API_URL || cachedSlotsAPIRootUrl,
      query: args,
    }),
  {
    normalizer: args => JSON.stringify(args[0]), // because first arg is an object
  },
);
