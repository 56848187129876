/**
 * Removes all of the `utm_` prefixes from query params.
 * @returns all of the `utm_` prefixed key value pairs without the `utm_` prefix
 */
export const parseUtmParams = (
  query: Record<string, unknown>,
): Record<string, unknown> | undefined => {
  const parsedQuery = Object.fromEntries(
    Array.from(Object.entries(query))
      .filter(([key]) => key.startsWith("utm_"))
      .map(([key, value]) => [key.replace("utm_", ""), value]),
  );
  return parsedQuery.source ? parsedQuery : undefined;
};
