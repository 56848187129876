import React, {useEffect} from "react";

import {Common} from "./types";

/**
 * This type allows `onKeyHandler` functions to handle `KeyboardEvent` and `React.KeyboardEvent`.
 */
type CommonKeyboardEvent = Common<KeyboardEvent, React.KeyboardEvent>;

type CommonKeyboardEventHandler = (e: CommonKeyboardEvent) => void | unknown;

const useEscape = (onEscape: CommonKeyboardEventHandler): void => {
  useEffect(() => {
    const handleEsc: CommonKeyboardEventHandler = event => {
      if (event.key === "Escape") onEscape(event);
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [onEscape]);
};

type Key =
  | "Enter"
  | "Space"
  | "Tab"
  | "Escape"
  | "ArrowRight"
  | "ArrowDown"
  | "ArrowLeft"
  | "ArrowUp"
  | "Alt"
  | "Control"
  | "Shift";

export const onKeyHandler =
  (keys: Key | Key[], onKeyDown: CommonKeyboardEventHandler) =>
  (e: CommonKeyboardEvent): void => {
    const shiftMatch = !keys.includes("Shift") || e.shiftKey;
    if (shiftMatch) {
      if (Array.isArray(keys) && keys.includes(e.key as Key)) {
        onKeyDown(e);
      } else if (e.key === keys) {
        onKeyDown(e);
      }
    }
  };

export const useKeyHandler = (keys: Key | Key[], onKeyDown: CommonKeyboardEventHandler): void => {
  useEffect(() => {
    const eventHandler = onKeyHandler(keys, onKeyDown);

    window.addEventListener("keydown", eventHandler);

    return () => {
      window.removeEventListener("keydown", eventHandler);
    };
  }, [keys, onKeyDown]);
};

export default useEscape;
