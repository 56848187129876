import Cookies from "js-cookie";
import {useEffect} from "react";
import {Cookie} from "src/constants/cookie";
import {RootState, useTypedSelector} from "src/store";

// Util to sync a given cookie with a piece of redux state
// Should be placed in top level `_app.tsx`
export const useSyncReduxCookie = (cookie: Cookie, selector: (state: RootState) => any) => {
  const slice = useTypedSelector(selector);

  useEffect(() => {
    const currentCookie = Cookies.get(cookie);
    const cookiesDoNotMatch = slice !== currentCookie;

    if (slice && cookiesDoNotMatch) Cookies.set(cookie, slice);
  }, [slice]);
};
