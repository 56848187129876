import {useMemo} from "react";

import {LocalStorageKey, getFromLocalStorageRaw, setInLocalStorageRaw} from "../localStorage";
import {useLocalStorageItemRaw} from "./useBrowserStorage";

export const setVisitId = (id: string) => setInLocalStorageRaw(LocalStorageKey.VisitId, id);

export const getVisitId = () => getFromLocalStorageRaw(LocalStorageKey.VisitId);

export const setVisitorId = (id: string) => setInLocalStorageRaw(LocalStorageKey.VisitorId, id);

export const getVisitorId = () => getFromLocalStorageRaw(LocalStorageKey.VisitorId);

const useVisitId = () => useLocalStorageItemRaw(LocalStorageKey.VisitId);

const useVisitorId = () => useLocalStorageItemRaw(LocalStorageKey.VisitorId);

export const useVisitData = () => {
  const visitId = useVisitId();
  const visitorId = useVisitorId();
  return useMemo(
    () => ({
      visitId,
      visitorId,
    }),
    [visitId, visitorId],
  );
};
