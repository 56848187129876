import {merge} from "lodash";
import memoizee from "memoizee";
import {v4} from "uuid";

import {dev} from "../../components/_common/_constants";
import {BookingStep} from "../../page-containers/booking/utils/bookingStep";
import {RecursivePartial} from "../../types";
import {addToEventQueue} from "../browser-storage/eventQueue";
import {getSessionId} from "../browser-storage/sessionId";
import {getTrafficId} from "../browser-storage/trafficId";
import {Action, CustomCategory, EventCategory, EventType, Label} from "./constants";
import {KeyValPair} from "./types";

export type ApptDetails = {
  apptId?: string;
  locationId?: string;
  apptReasonId?: string;
  specialtyId?: string;
  slotId?: string;
  milesAway?: number;
  timeToAppt?: number;
};

type ExtraData = {
  [key: string]: unknown;
  action?: Action;
  label?: Label;
  category?: CustomCategory;
  bookingStep?: BookingStep;
  apptDetails?: ApptDetails;
};

export type DraftEvent = {
  id?: string;
  typeId: EventType;
  category?: EventCategory;
  extraData?: ExtraData;
};

export const getEventTypeFromId = memoizee((typeId: EventType) => {
  const eventTypeKeyVal = Object.entries(EventType).find(([, value]) => value === typeId);
  if (!eventTypeKeyVal) throw TypeError("Event `typeId` does not exist");
  return eventTypeKeyVal[0];
});

const objToKeyVals = (obj: Record<string, unknown>): KeyValPair[] =>
  Object.entries(obj)
    .filter(([, value]) => value !== undefined)
    .map(([key, value]) => ({
      key,
      value: typeof value === "string" ? value : JSON.stringify(value),
    }));

const formatExtraData = <T extends {extraData: ExtraData}>({extraData, ...draftEvent}: T) => ({
  ...draftEvent,
  extraData: objToKeyVals(extraData),
});

const getDefaultEventData = (typeId: EventType) => ({
  id: v4(),
  sessionId: getSessionId() || "",
  category: EventCategory.Custom,
  typeName: getEventTypeFromId(typeId),
  extraData: {
    trafficId: getTrafficId(),
  },
  time: {
    epochMs: Date.now(),
  },
});

export const queueEvent = (
  draftEvent: DraftEvent,
  commonEventData: RecursivePartial<DraftEvent> = {},
) => {
  const event = formatExtraData(
    merge(getDefaultEventData(draftEvent.typeId), commonEventData, draftEvent),
  );
  if (dev && process.env.NEXT_PUBLIC_LOG_ANALYTICS_EVENTS) {
    console.log(event.typeName, event);
  }
  addToEventQueue(event);
};

export const getEventQueuer = memoizee(
  (commonEventData: RecursivePartial<DraftEvent>) => (draftEvent: DraftEvent) =>
    queueEvent(draftEvent, commonEventData),
  {normalizer: JSON.stringify},
);
