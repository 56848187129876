/**
 * Decorate a "fetch" function so that errors get logged for bad HTTP statuses.
 * These will get sent to sentry, so the goal here is to log error statuses which indicate
 * an actionable _client-side_ bug. Server errors are monitored by servers and logged by other
 * parts of the tech stack.
 *
 * @param delegate A fetch function to decorate.
 * @param options Which status codes in the 4xx range are "expected?" In other words,
 *   which 4xx statuses should engineers _not_ be notified on, if they happen in prod?
 */
export function withSentryErrorLogging(
  delegate: typeof fetch,
  options: Partial<Options> = {},
): typeof fetch {
  const {allowedStatusCodes} = {...defaults, ...options};
  return async (input: RequestInfo | URL, init?: RequestInit) => {
    const response = await delegate(input, init);
    const status = response.status;
    if (status >= 400 && status < 500 && !allowedStatusCodes.includes(response.status)) {
      console.error(`${url(input)} returned a ${response.status}. This is a client-side bug!`);
    }
    return response;
  };
}

function url(input: RequestInfo | URL): string {
  if (input instanceof URL) {
    return input.href;
  }
  if (input instanceof Request) {
    return input.url;
  }
  return input;
}

type Options = {
  /**
   * A list of status codes in the 4xx range which are "expected."
   * These will not be logged to sentry as errors.
   */
  allowedStatusCodes: number[];
};

const defaults = {
  allowedStatusCodes: [],
};
