export const ApptReasonId = {
  BREAST_LUMP: "173aa09a-6af1-4f63-9c76-d67f0b5d3cd0",
  CONCUSSION_TEST: "015e6a91-ed3a-47fb-b3b7-f655e78fe00c",
  COVID_CONCERNS: "a516b4f7-339d-47bc-910d-3b89b81e2e9a",
  COVID_I_TESTED_POSITIVE: "a88ae69b-a198-4780-83ac-562041ca5994",
  COVID_TESTING_MCM_ONLY: "b1a764c2-47e1-49d3-a98e-a79e16cbcdc1",
  COVID_TESTING_NO_SYMPTOMS: "10f05a62-e555-4fcc-9749-9d28d95b65d6",
  COVID_SICK_VISIT: "cde77a27-bc9f-4006-876d-b1c7c7db0f37",
  COVID_TRAVEL_CLEARANCE_TESTING: "23b5bd54-42e3-4e5a-a05b-b21c4be55601",
  VIRTUAL_COVID_19_VISIT: "0043474f-bf4d-4815-becc-07f086f03513",

  COVID_VAX: "bea54e8f-8559-4af6-b5b0-0c0201e80b93",
  COVID_VAX_FIRST_DOSE: "b0925fc2-41fd-4e83-948e-74efe6f575b2",
  COVID_VAX_SECOND_DOSE: "4435ea0c-54ba-451f-961c-e74eb246dc47",
  COVID_VAX_ADDITIONAL_DOSE: "94f45962-602d-4dd0-a4f2-262ad0bdfec7",

  FLU_VAX: "82a4a528-d991-429c-a919-dd79866d4980",
  GENERAL_URGENT_CARE: "679ba617-02d6-4694-93d2-d5d1e4f66efa",
  NEXT_DAY_RT_PCR: "b475dca5-c9cf-41b5-abed-9b69982088ad",
  RASH: "7d6b539f-fea3-4c7c-8ce6-46020e7f0099",
  UTI_SYMPTOMS: "b1c56f9a-c486-4922-b54c-4abcd27b9189",
  WOMENS_HEALTH: "16c67a0d-936b-4b3c-9876-8d4ee5882d6c",
  SAME_DAY_ANTIGEN: "648fb922-da45-46ab-8a0c-2fcc7527dec5",
  SAME_DAY_RT_PCR: "cf249a2f-d9c8-49b3-9bb8-41e72942c87f",
} as const;

// NOTE: All of these appointment reasons are now stored in CMS, so once the
// GROWTH_DISCOVERY_APPT_REASON_PAGE_1 feature is permanently enabled, we should
// remove these and also remove `Reason.v2.tsx`.
export const ApptReasonSlug = {
  ALLERGY: "allergy-symptoms",
  FLU: "flu-shot",
  PINK_EYE: "red-eye-pink-eye",
  RASH: "rash",
  RX: "medication-prescription-refill",
  SINUS: "sinus-infection",
  SORE_THROAT: "sore-throat",
  STD: "sti-test",
  UTI: "urinary-tract-infection",
  YEAST_INFECTION: "yeast-infection",
};

export const apptReasonSlugs = Object.values(ApptReasonSlug);
