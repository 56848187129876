/**
 * An error which remembers the server's statusCode.
 *
 * This can be useful for callers during error handling. Some examples:
 *   1. 5xx, 401s, and 403s shouldn't go Sentry because they're not actionable bugs.
 *   2. 5xx errors can be retried a few times before giving up, whereas 4xx's can't.
 *   3. 4xxs, and 5xxs if the retries fail, should result in a UI-specific message being
 *      shown to the user.
 *   4. 401s and 403s should prompt some kind of login screen.
 *
 * Decorators inside this package use this error type to enable behaviors like these.
 */
export class HTTPError extends Error {
  statusCode: number;

  constructor(statusCode: number, body: string) {
    super(`Server returned ${statusCode} with response body ${body}`);
    this.statusCode = statusCode;
  }
}
