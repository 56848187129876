import {Event} from "../analytics/types";
import {SessionStorageKey, getFromSessionStorage, setInSessionStorage} from "../sessionStorage";

export const setEventQueue = (events: Event[]) =>
  setInSessionStorage(SessionStorageKey.EventQueue, events);

export const getEventQueue = () => getFromSessionStorage(SessionStorageKey.EventQueue) || [];

export const addToEventQueue = (event: Event) => setEventQueue([...getEventQueue(), event]);

export const clearEventQueue = () => setEventQueue([]);
