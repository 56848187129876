import {PayloadAction, createSlice} from "@reduxjs/toolkit";
import {FeatureFlag} from "@services/featureFlagConstants";

import {HYDRATE} from "../index";

export type FeatureFlags = Partial<Record<FeatureFlag, unknown>>;

const initialState = {} as FeatureFlags;

export const featureFlagSlice = createSlice({
  name: "featureFlags",
  initialState,
  reducers: {
    setFeatureFlags: (state, action: PayloadAction<FeatureFlags>) => ({
      ...state,
      ...action.payload,
    }),
  },

  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, action) => ({
      ...state,
      ...action.payload.featureFlags,
    }));
  },
});

export const {setFeatureFlags} = featureFlagSlice.actions;

export default featureFlagSlice.reducer;
