import {RootStateLocation} from "src/store/types";

import {PracticeId} from "./practiceIds";
import {SpecialtyId} from "./specialtyIds";

export const LocationId = {
  ALASKA_SAN_DIEGO: "6421701e-7d27-46c1-8281-94a26b2b2799",
  ATASCOCITA_TEXAS: "17f051fb-2779-4437-ab95-388be3231456",
  BROOKLYN: "6b28ad7b-8108-4160-9a74-13dd3c79908c",
  BERKELEY: "de986c3c-0287-4cd4-a2f0-1e68681a5077",
  SF_CASTRO: "a4af26de-5c64-4fba-921b-6cdfb6e7a80d",
  DEMO_TRAVEL_CLEARANCE: "6fe19a3a-7f6e-45af-bf5c-48f26513b9a1",

  EL_CAMINO_SAN_JOSE: "3f768793-dc13-4725-a81f-b92f19f39bfd",
  EL_CAMINO_CUPERTINO: "65bd9d37-c4c3-42f6-b93f-78ad543e7977",
  EL_CAMINO_MOUNTAINVIEW: "b0032e92-13da-46d4-8cb7-de863812d8f0",

  FAIRFIELD: "dc9ceaec-86aa-4f7b-81a4-12d411c53a29",
  HILLS_PLAZA: "05af5710-bd5e-467d-bd7d-8f5f9f0a8e99",
  HIALEAH: "15669a91-482d-4785-9c02-95f6ae9479bb",
  LA_COSTA: "82477124-e011-4dbc-9316-10d895d81dfe",
  LITTLETON_COLORADO: "845da67b-2e5a-45f2-b03d-53b8baaeed0d",
  LONG_BEACH: "4ef88e33-3796-47b0-88af-d9b7b5dd43c8",

  MED7_SACRAMENTO: "fd1a78a4-1ecd-4334-bc76-4b10556e4b2a",
  MED7_CARMICHAEL: "3f763910-7d9a-4d68-bfbc-6c62fbbf8a82",
  MED7_ROSEVILLE: "dc46929a-cc9f-44fd-87c5-1325d7006123",
  MED7_FOLSOM: "37ff6794-27f7-4d95-b593-268e7ea3bb9d",

  MOBILE_ALABAMA: "2ccdb8c2-3e6c-4f6e-9a07-d1042b32cd90",
  NEWBURY_PARK: "4aae9a88-a5bc-4640-8996-534b98f6c68a",

  POPUP_BRANNON_SF: "48820f69-5c58-4280-8cc2-4cef4dabef1b",
  POPUP_BROADWAY_LA: "699f7011-ca4a-4612-808d-c1ad27efbeed",
  POPUP_HAVEMEYER: "6b28ad7b-8108-4160-9a74-13dd3c79908c",
  POPUP_SIXTH_AVE: "adc2c199-388f-4370-b031-52e49a4291e2",

  SANTA_ANA: "cc9223ad-48a1-4e73-88dc-d475acb7c8a9",

  SAUC_BROADWAY: "79c86674-7429-4cd2-8342-3869b6d3e9fe",
  SAUC_CAMPBELL_AVE: "9e158db4-8bf2-4d5c-b765-3683f6550677",
  SAUC_GRANT: "29552656-cdfb-49c4-9210-e6dc5a3228c9",
  SAUC_HARRISON: "659fdedb-fa50-44ec-9951-90fed0eeb2e0",
  SAUC_INA_ROAD: "7e490855-5acb-4e6c-9f7d-9b802014090d",
  SAUC_IRVINGTON_ROAD: "f47eef16-b4df-4c6d-a0e6-bb0b5b16ae20",
  SAUC_ORACLE: "662a316a-36e7-486a-93e2-dbd2a8aff82a",
  SAUC_RIVER: "24fdac77-22c3-441c-8d90-a44e26108aeb",
  SAUC_TANGERINE: "dfadc4fc-5845-468c-90bd-ad5874179d4d",

  ST_MARYS_GALENA: "0da8d758-94eb-4cfd-ad0b-7389d44d7dd4",
  ST_MARYS_NORTH_VALLEYS: "804897dc-3158-4d5a-b648-1d583e81a89c",
  ST_MARYS_RENO_NW: "106cfcb7-4642-40ca-a093-7bda65f501ab",
  ST_MARYS_SPANISH_SPRINGS: "aba7d942-00d3-413e-9861-a4ad05d9bc93",

  SUNNYVALE: "ca07adce-37aa-4d2b-8d23-7a5510602737",

  TRAVEL_CLEARANCE_BELLEVUE: "cebfb290-270b-4fca-92ca-b25f086a0883",
  TRAVEL_CLEARANCE_ECHO_PARK: "3ed46631-6c3e-4d7c-9f77-f886d674e989",
  TRAVEL_CLEARANCE_LA_COSTA: "af3d58f7-18e0-45c1-8e77-3961d3c5e340",
  TRAVEL_CLEARANCE_NORTHGATE_MALL_SEATTLE: "f12cd07f-8e2a-40de-a91b-eaab1a971059",
  TRAVEL_CLEARANCE_NORTHGATE_TUKWILA_WA: "b927cd08-1663-4f24-a103-9193f2001650",
  TRAVEL_CLEARANCE_PORTLAND: "d6916b82-3d17-4abb-8d36-4967b8400f61",
  TRAVEL_CLEARANCE_SAN_JOSE: "4cbc3fb5-6fb3-4ece-aa03-b971738b3304",
  TRAVEL_CLEARANCE_SEATTLE_SEA_TAC: "8c1a955b-34c2-4356-bbba-fd93c3ddc49c",

  VACAVILLE: "1a2177af-c402-41f5-96de-3de2764e56dd",

  SAN_CLEMENTE_HYBRID_MD: "088bd903-9eca-4063-811a-ba169d194235",

  MY_INSTADOC_MONROE: "ed624224-6093-48ff-9299-d5f001ffec64",

  CENTRAL_NEW_JERSEY_SKILLMAN: "c5fe46b1-45ca-4ed4-a008-a7191f45b6a9",

  BOSTON_MA_DOWNTOWN: "fc4219a1-c36c-4283-9b0e-5f65b35e2be9",
  DRESHER_PA: "3b6ad177-4453-4adc-9507-8f6c29b6c34e",
  RICHMOND_VA_CARYTOWN: "943a86a3-4105-4a9d-a7f9-2d1e550e7929",
  COLUMBUS_OH_EASTON: "2b97ebbc-51fa-45fa-ab96-825996c7f075",
  COLUMBUS_OH_THE_SHOPS: "6ed6f54b-ed13-4487-8d25-cfba913b2656",
  SMITHRIDGE_PLAZA: "9479fa3d-263b-4b5d-a0dc-be8ab46c1d49",
  ANTIOCH_JOHN_MUIR: "d38ce7c8-2a9e-4b65-a610-958d3c96a86a",
  ALBANY_JOHN_MUIR: "8e5b1761-4ec0-49a9-a41c-6828a36286a7",
  OAKLAND: "b6031ea2-749a-45c9-b704-7523ce64b9c2",
  SAN_LEANDRO: "51a660d4-3057-4b14-b8dd-123124ecca6e",
} as const;

export const saucLocIds = [LocationId.SAUC_ORACLE];

export const med7LocationIds = [LocationId.MED7_ROSEVILLE];

export const elCaminoLocIds: string[] = [
  LocationId.EL_CAMINO_MOUNTAINVIEW,
  LocationId.EL_CAMINO_CUPERTINO,
  LocationId.EL_CAMINO_SAN_JOSE,
];

export const stMarysLocIds = [
  LocationId.ST_MARYS_RENO_NW,
  LocationId.ST_MARYS_SPANISH_SPRINGS,
  LocationId.ST_MARYS_GALENA,
  LocationId.ST_MARYS_NORTH_VALLEYS,
  LocationId.SMITHRIDGE_PLAZA,
];

export const johnMuirLocIds = [
  LocationId.ANTIOCH_JOHN_MUIR,
  LocationId.ALBANY_JOHN_MUIR,
  LocationId.OAKLAND,
  LocationId.BERKELEY,
  LocationId.SAN_LEANDRO,
];

const popupLocIds = [
  LocationId.POPUP_BRANNON_SF,
  LocationId.POPUP_BROADWAY_LA,
  LocationId.POPUP_HAVEMEYER,
  LocationId.POPUP_SIXTH_AVE,
];

export const alaskaOnlyLocationIds: string[] = [LocationId.TRAVEL_CLEARANCE_SEATTLE_SEA_TAC];

export const elCaminoLocSlugs = ["san-jose", "mountain-view", "cupertino"];

export const isVaxLocation = (loc: RootStateLocation): boolean => loc.typ === "Vaccination";

/**
 * Should only be used as a fallback if VAX_LOC_IDS is unavailable
 */
export const isLaVaxLocation = (l: RootStateLocation): boolean =>
  /lafd/gi.test(l.name) || l.address.city === "Los Angeles";
/**
 * Should only be used as a fallback if VAX_LOC_IDS is unavailable
 */
export const isBerkeleyVaxLocation = (l: RootStateLocation): boolean =>
  l.practiceId === PracticeId.BERKELEY_VAX;

// all locations data we need
// (even they are deactivated, we might need their data)
export const isAllowedLocation = (l: RootStateLocation) =>
  !isVaxLocation(l) &&
  l.practiceId !== PracticeId.BERKELEY_VAX &&
  (alaskaOnlyLocationIds.includes(l.id) ||
    elCaminoLocIds.includes(l.id) ||
    (!l.deactivatedAt &&
      (l.id === LocationId.MOBILE_ALABAMA || !l.name.includes("Mobile")) && // discard all except mobile alabama
      !l.name.includes("Closed") &&
      !/ch\semployee/gi.test(l.name) &&
      !/c4b/gi.test(l.name) &&
      !l.name.includes("Internal")));

export const getAllowedLocations = (locations: RootStateLocation[]) =>
  locations.filter(isAllowedLocation);

// all locations we want to render in website
// (eg: we dont want vax locations in footer or in locations page)
export const getAllowedLocationsToRender = (locations: RootStateLocation[]) =>
  locations.filter(
    l =>
      !alaskaOnlyLocationIds.includes(l.id) &&
      !isVaxLocation(l) &&
      l.practiceId !== PracticeId.BERKELEY_VAX &&
      l.id !== LocationId.DEMO_TRAVEL_CLEARANCE,
  );

export const isPopupLoc = (name: string): boolean => (name || "").includes("Pop-Up");

export const popupLocationsFilter = (l: RootStateLocation): boolean =>
  (popupLocIds as string[]).includes(l.id) || !isPopupLoc(l.name);

export const isAlaskaAirlinesLoc = (locationId: string): boolean =>
  alaskaOnlyLocationIds.includes(locationId);

export const offersTravelClearanceServices = (location: RootStateLocation): boolean =>
  /Travel\sClearance/gi.test(location.name) ||
  location.services.some(s => s.name === "COVID-19 Travel Clearance Test") ||
  (location.specialtyIds.includes(SpecialtyId.COVID_TRAVEL_CLEARANCE) &&
    (location.specialtyIds.length === 1 ||
      (location.specialtyIds.includes(SpecialtyId.COVID_TESTING_MOBILE) &&
        location.specialtyIds.length === 2)));
