/**
 * Util which can be used to achieve something like a for promises from functional programming.
 *
 * Usage looks like:
 *
 * Promise.resolve("whatever")
 *   .then(tee(console.log))
 *   .then((value) => typeof value === "string") // is true
 */
export default <T1>(f: (arg: T1) => unknown) =>
  (arg: T1) =>
    Promise.resolve(f(arg)).then(() => arg);
