import * as Sentry from "@sentry/nextjs";
import {ErrorBoundaryProps} from "@sentry/react/types/errorboundary";
import NextErrorComponent, {ErrorProps} from "next/error";
import React from "react";

import {CarbonPage} from "../CarbonPage";

type Props = Partial<
  ErrorBoundaryProps &
    ErrorProps & {
      hasGetInitialPropsRun: boolean;
      err: unknown;
      eventId: string;
    }
>;

export const CarbonError: CarbonPage<Props> = ({
  statusCode,
  hasGetInitialPropsRun,
  err,
  eventId,
}) => {
  if (!hasGetInitialPropsRun && err) {
    Sentry.captureException(err);
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        fontSize: "1.8rem",
      }}
    >
      <div
        style={{
          display: "grid",
          gridAutoFlow: "row",
          gridGap: "1rem",
          textAlign: "center",
          fontSize: "1.125rem",
        }}
      >
        <img
          src="/static/img/v5/logo.svg"
          width={180}
          alt="Carbon Health logo"
          style={{
            justifySelf: "center",
          }}
        />
        <span>{statusCode ? `A ${statusCode} error occurred` : "An error occurred"}</span>
        {eventId && <strong>{eventId}</strong>}
        <a href="/">Go to homepage &rarr;</a>
      </div>
    </div>
  );
};

CarbonError.getInitialProps = async context => {
  const errorInitialProps = await NextErrorComponent.getInitialProps(context);
  const props = {
    // Workaround for https://github.com/vercel/next.js/issues/8592
    hasGetInitialPropsRun: true,
    namespacesRequired: [],
    ...errorInitialProps,
  };

  const {res, err, asPath} = context;

  // Returning early because we don't want to log 404 errors to Sentry.
  if (res?.statusCode === 404) {
    return props;
  }

  if (err) {
    Sentry.captureException(err);

    // Flushing before returning is necessary if deploying to Vercel, see
    // https://vercel.com/docs/platform/limits#streaming-responses
    await Sentry.flush(2000);

    return props;
  }

  // If this point is reached, getInitialProps was called without any
  // information about what the error might be. This is unexpected and may
  // indicate a bug introduced in Next.js, so record it in Sentry
  Sentry.captureException(new Error(`_error.tsx getInitialProps missing data at path: ${asPath}`));
  await Sentry.flush(2000);

  return props;
};

CarbonError.optOutOfPracticeData = true;
export default CarbonError;
