import {useTranslation} from "ni18n";
import React, {ComponentProps} from "react";
// eslint-disable-next-line no-restricted-imports
import {Trans as ReactTrans} from "react-i18next";

import {I18nNamespace} from "./i18n-namespaces";

type ReactTransProps = ComponentProps<typeof ReactTrans>;

const Trans = (props: Omit<ReactTransProps, "t">): React.ReactElement => {
  const i18n = useTranslation(props.ns as I18nNamespace);
  // @ts-expect-error TS2322: Type 'Translate' is not assignable to type 'Translate & TFunction<string | string[], undefined>'.
  return <ReactTrans shouldUnescape t={i18n.t} {...props} />;
};

export default Trans;
