import {useMemo, useState} from "react";
import {v4} from "uuid";

import {isBrowser} from "../components/_common/_constants";
import {usePostEventQueue} from "../utils/analytics/usePostEventQueue";
import {setDevice} from "../utils/browser-storage/device";
import {getSessionStart, setSessionStart} from "../utils/browser-storage/sessionDuration";
import {getSessionId, setSessionId} from "../utils/browser-storage/sessionId";
import {getTrafficId, setTrafficId} from "../utils/browser-storage/trafficId";
import {
  getVisitId,
  getVisitorId,
  setVisitId,
  setVisitorId,
} from "../utils/browser-storage/visitData";
import {makeDevice} from "../utils/makeDevice";
import {usePageNavigationAnalytics} from "./usePageNavigationAnalytics";

export const useStartSession = (
  visitId: string | undefined,
  visitorId: string | undefined,
  ipAddress: string | undefined,
  lng: string,
  referer: string | null,
) => {
  const [sessionId] = useState(getSessionId() || v4());
  const [trafficId] = useState(getTrafficId() || v4());
  const [sessionStart] = useState(getSessionStart() || Date.now());
  const device = useMemo(
    () =>
      makeDevice({
        ipAddress: ipAddress,
        userAgent: typeof navigator !== "undefined" ? navigator.userAgent : "",
        languageCode: lng,
        deviceId: visitorId || v4(),
      }),
    [ipAddress, lng, visitorId],
  );

  if (isBrowser()) {
    if (!getVisitId() && visitId) setVisitId(visitId);
    if (!getVisitorId() && visitorId) setVisitorId(visitorId);
    if (!getSessionId()) setSessionId(sessionId);
    if (!getSessionStart()) setSessionStart(sessionStart);
    if (!getTrafficId()) setTrafficId(trafficId);
    setDevice(device);
  }

  usePageNavigationAnalytics(trafficId, referer);
  usePostEventQueue();
};
