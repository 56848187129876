import "isomorphic-fetch";

import {limitToMonolith} from "./monolith";
import {limitToNextApi} from "./nextApi";
import {withResponseValidation} from "./responseValidation";
import {withSentryErrorLogging} from "./sentryErrorLogging";

/**
 * Get a fetch-like function suitable for making calls to the monolith.
 *
 * @param optionOverrides Options to customize the returned fetch function. See the comments
 *   on the Options type for more details.
 */
export default function monolith(
  optionOverrides: Partial<Options> = {},
): (path: string, init?: RequestInit) => Promise<Response> {
  const {allowedStatusCodes} = {...defaults, ...optionOverrides};
  return limitToMonolith(
    withResponseValidation(withSentryErrorLogging(fetch, {allowedStatusCodes}), {
      allowedStatusCodes,
    }),
  );
}

/**
 * Get a fetch-like function suitable for making calls to /api routes served by
 * carbon-website-next.
 *
 * @param optionOverrides Options to customize the returned fetch function. See the comments
 *   on the Options type for more details.
 */
export function nextApi(
  optionOverrides: Partial<Options> = {},
): (path: string, init?: RequestInit) => Promise<Response> {
  const {allowedStatusCodes} = {...defaults, ...optionOverrides};
  return limitToNextApi(
    withResponseValidation(withSentryErrorLogging(fetch, {allowedStatusCodes}), {
      allowedStatusCodes,
    }),
  );
}

/**
 * Fetch options to customize the behavior of the returned function.
 */
type Options = {
  /**
   * Typically, 4xx errors indicate are client-side bugs which we need to fix. Our enhanced fetch
   * functions return rejected Promises and log these to Sentry, which ultimately alerts
   * engineering that there's a bug.
   *
   * This isn't true of all endpoints though. Some product use-cases expect a 404, or
   * want to behave differently (e.g. redirect the user to a login page) on 401s.
   *
   * This option lets callers define the 4xx codes which should _not_ be treated as errors.
   * In these cases the fetch will return a successful Promise<Response> if the server returns them.
   */
  allowedStatusCodes: number[];
};

const defaults: Options = {
  allowedStatusCodes: [],
};
