import {getApiUrl} from "../../components/_common/_constants";
import {getToken} from "../browser-storage/tokens";
import {getVisitId, getVisitorId} from "../browser-storage/visitData";

const apiUrl = getApiUrl();

/**
 * Wrap "fetch" with a "fetch-like" function that has a few minor differences:
 *
 *   1. If present, the patient's auth token will be included with the request.
 *   2. The first arg must be a path, not a full URL. The URL's hostname will be
 *      hardcoded to Carbon's API to prevent accidental leaks to other domains.
 *
 * The goal here is to consolidate shared code, but still behave _like_ fetch so
 * that we don't end up maintaining abstractions on top of abstractions, and
 * frontend engineers who join the company will have a low learning curve.
 */
export function limitToMonolith(
  delegate: typeof fetch,
): (path: string, init?: RequestInit) => Promise<Response> {
  return async (path: string, init?: RequestInit) => {
    const url = `${apiUrl}${path}`;

    const token = getToken();
    const defaultHeaders = {
      ...getDefaultHeaders(init?.body),
      ...(token ? {authorization: `Bearer ${token}`} : {}),
    };

    if (init) {
      const {headers, ...otherInitOptions} = init;
      return delegate(url, {
        headers: {
          ...defaultHeaders,
          ...validateHeaders(headers),
        },
        ...otherInitOptions,
      });
    } else {
      return delegate(url, {headers: defaultHeaders});
    }
  };
}

export function getDefaultHeaders(body?: BodyInit | null) {
  const visitId = getVisitId();
  const visitorId = getVisitorId();
  return {
    carbonAppType: "Web",
    ...(body ? {"Content-Type": "application/json"} : {}),
    ...(visitorId ? {carbonVisitorId: visitorId} : {}),
    ...(visitId ? {carbonVisitId: visitId} : {}),
  };
}

/**
 * We'll probably never hit a use-case where we pass headers as anything but a raw JS object...
 * but this just makes sure of it.
 *
 * If this error starts happening, we can come back and implement it for real.
 */
export function validateHeaders(headers?: HeadersInit): Record<string, string> {
  if (headers instanceof Headers || Array.isArray(headers)) {
    throw new Error("Support for Header objects and arrays is not yet implemented.");
  }
  return headers ?? {};
}
