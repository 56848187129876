import {HTTPError} from "./httpError";

/**
 * Decorate a fetch function so that its Promises fail with an APIError on 4xx and 5xx responses.
 *
 * @param delegate The original fetch function.
 * @param options Options to configure the decorator's behavior.
 * @return A drop-in fetch replacement function which fails with HTTPError if the server returns
 *         an unexpected 4xx or 5xx response.
 */
export function withResponseValidation(
  delegate: typeof fetch,
  options: Partial<Options> = {},
): typeof fetch {
  const {allowedStatusCodes} = {...defaults, ...options};
  return async (input: RequestInfo | URL, init?: RequestInit) => {
    const response = await delegate(input, init);
    const isError =
      response.status >= 400 &&
      response.status < 600 &&
      !allowedStatusCodes.includes(response.status);
    if (isError) {
      const body = await response.text();
      throw new HTTPError(response.status, body);
    }
    return response;
  };
}

type Options = {
  /**
   * A list of HTTP codes in the 4xx or 5xx range which should _not_ be converted to errors. The
   * fetch returned by the decorator will return a successful Promise<Response> if the server
   * returns one of these codes.
   */
  allowedStatusCodes: number[];
};

const defaults: Options = {
  allowedStatusCodes: [],
};

/**
 * Decorate a "fetch" function so that it returns an error on any bad responses from the server.
 *
 * @deprecated This doesn't give callers enough info to respond appropriately. For example, we
 *             should never send 5xx , 401, or 403s to Sentry, and only _sometimes_ 404s, depending
 *             on the endpoint in question. Similarly, it's safe to retry requests with 5xx
 *             responses, but not 4xx. Callers should prefer "errorOnErrorCodes" instead so that
 *             the APIError can be inspected to get more info about the error.
 */
export function withGenericErrorNonOks(delegate: typeof fetch): typeof fetch {
  return async (input: RequestInfo | URL, init?: RequestInit) => {
    const response = await delegate(input, init);
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response;
  };
}
