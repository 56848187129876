import {createSlice} from "@reduxjs/toolkit";

import {HYDRATE} from "../index";

export type IsKeyboardUserState = boolean;

const initialState = false as IsKeyboardUserState;

export const isKeyboardUserSlice = createSlice({
  name: "isKeyboardUser",
  initialState,
  reducers: {
    setIsKeyboardUser: () => true,
  },
  extraReducers: builder => {
    builder.addCase(HYDRATE, state => state);
  },
});

export const {setIsKeyboardUser} = isKeyboardUserSlice.actions;

export default isKeyboardUserSlice.reducer;
