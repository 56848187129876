import {PayloadAction, createSlice} from "@reduxjs/toolkit";

import {HYDRATE} from "../index";

export type MetadataState = {
  title?: string;
  description?: string;
  h1?: string;
};

// @ts-expect-error TS2352: Conversion of type '{ title: null; description: null; h1: null; }' to type 'MetadataState' may be a mistake because neither type sufficiently overlaps with the other. If this was intentional, convert the expression to 'unknown' first.
const initialState = {
  title: null,
  description: null,
  h1: null,
} as MetadataState;

export const metadataSlice = createSlice({
  name: "metadata",
  initialState,
  reducers: {
    setMetadata: (state, action: PayloadAction<Partial<MetadataState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, action) => ({
      ...state,
      ...action.payload.metadata,
    }));
  },
});

export const {setMetadata} = metadataSlice.actions;

export default metadataSlice.reducer;
