import "isomorphic-fetch";

import {IncomingMessage, ServerResponse} from "http";

import {PracticeApptReason} from "@services/monolith/appointmentReasons";
import {setCookie} from "cookies-next";
import {groupBy} from "lodash";
import memoizee from "memoizee";
import moment from "moment-timezone";
import queryString from "query-string-for-all";
import {GA as GAParams} from "src/components/_common/types";

import {msToSec} from "../../workers/common/msToSec";
import {dev, getApiUrl, isBrowser, laTimezone} from "../components/_common/_constants";
import {calculateDistance, getCookie} from "../components/_common/Carbon";
import {HealthPass} from "../components/healthpass/healthPassTypes";
import {MsMap} from "../constants/MsMap";
import {PracticeId} from "../constants/practiceIds";
import {defaultSlotQualificationIds} from "../constants/qualificationIds";
import {Metadata} from "../store/types";
import {withGenericErrorNonOks} from "../utils/fetch/responseValidation";
import {fetchCachedSlot} from "../utils/fetchCachedSlot";
import {ignoreArrays} from "../utils/ignoreArrays";
import * as types from "./types";
import {ApptSlotBlock} from "./types";

export const apiUrl = getApiUrl();

export function guess(): string {
  try {
    return moment.tz.guess();
  } catch (err) {
    return laTimezone; // could also be something like moment.tz.names()[0];
  }
}
const DEFAULT_TIMEZONE = guess();

export const log = <T>(response: T) => {
  console.log("API RESPONSE:", response);
  return response;
};

const fetchValidated = withGenericErrorNonOks(fetch);

const formatVisitDataHeaders = (visitData: {
  visitId: string | undefined;
  visitorId: string | undefined;
}) => ({
  CarbonVisitId: visitData.visitId,
  CarbonVisitorId: visitData.visitorId,
});

export const api = {
  setVisitCookies: (
    responseVisitId: string,
    responseVisitorId: string,
    res?: ServerResponse,
    req?: IncomingMessage,
  ): void => {
    setCookie("visitId", responseVisitId, {res, req, maxAge: msToSec(MsMap.ONE_YEAR)});
    setCookie("visitorId", responseVisitorId, {res, req, maxAge: msToSec(MsMap.ONE_YEAR)});
  },
  getVisitHeaders: (req?: IncomingMessage) => {
    const visitId = getCookie({req, name: "visitId"});
    const visitorId = getCookie({req, name: "visitorId"});

    return {
      visitId: typeof visitId === "string" ? visitId : undefined,
      visitorId: typeof visitorId === "string" ? visitorId : undefined,
    };
  },
  getDefaultHeaders: (req?: IncomingMessage, sendVisitHeaders = false) => {
    const clientIp =
      ignoreArrays(req?.headers && req.headers["x-real-ip"]) ||
      ignoreArrays(req?.headers && req.headers["x-forwarded-for"]) ||
      ignoreArrays(req?.headers && req.headers["X-Forwarded-For"]) ||
      (req?.headers && req.socket?.remoteAddress) ||
      "";

    const userAgent = (req?.headers && req.headers["user-agent"]) || undefined;
    const visitHeaders = sendVisitHeaders ? formatVisitDataHeaders(api.getVisitHeaders(req)) : {};

    return {
      CarbonAppType: "Web",
      ...(clientIp
        ? {
            "X-Forwarded-For": clientIp.startsWith("::ffff:")
              ? clientIp.replace("::ffff:", "")
              : clientIp,
          }
        : {}),
      ...(userAgent ? {"User-Agent": userAgent} : {}),
      ...visitHeaders,
    };
  },
  get: (
    path?: string,
    params?: Record<string, any>,
    headerParams = {},
    external = false,
    req?: IncomingMessage,
    sendVisitHeaders = false,
  ) => {
    const qs = params ? `?${queryString.stringify(params)}` : "";
    // console.log('get', `${apiUrl}${path}${qs}`);
    return fetchValidated(`${external ? "" : apiUrl}${path}${qs}`, {
      headers: {
        ...api.getDefaultHeaders(req, sendVisitHeaders),
        ...headerParams,
      },
    })
      .then(response => response.json())
      .catch(error => console.error("Error:", error));
  },

  post: (
    path: string,
    data?: unknown,
    external = false,
    headerParams = {},
    // @ts-expect-error TS7006: Parameter 'credentials' implicitly has an 'any' type.
    credentials?,
    req?: IncomingMessage,
    sendVisitHeaders = false,
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...api.getDefaultHeaders(req, sendVisitHeaders),
      ...headerParams,
    };

    const options = {
      method: "POST",
      credentials,
      headers,
      body: JSON.stringify(data || {}),
    };

    const root = external ? path : `${apiUrl}${path}`;

    return fetchValidated(root, options).catch(error => {
      console.error("Error:", error);
      return error;
    });
  },

  delete: (path: string, headerParams = {}, req?: IncomingMessage, sendVisitHeaders = false) =>
    fetchValidated(`${apiUrl}${path}`, {
      method: "DELETE",
      headers: {
        ...api.getDefaultHeaders(req, sendVisitHeaders),
        ...headerParams,
      },
    }),

  getWithTimeout: (
    timeout = 7000,
    path?: string,
    // @ts-expect-error TS7006: Parameter 'data' implicitly has an 'any' type.
    data?,
    external = false,
    headerParams = {},
    req?: IncomingMessage,
    sendVisitHeaders = false,
  ) => {
    const headers = {
      ...api.getDefaultHeaders(req, sendVisitHeaders),
      ...headerParams,
    };

    const options = {
      headers,
    };

    const root = external ? `${path}` : `${apiUrl}${path}`;

    return Promise.race([
      fetch(root, options),
      new Promise<never>((_, reject) => setTimeout(() => reject(new Error("timeout")), timeout)),
    ]);
  },

  postWithTimeout: (
    timeout = 7000,
    path?: string,
    data?: Record<string, any>,
    external = false,
    headerParams = {},
    // @ts-expect-error TS7006: Parameter 'credentials' implicitly has an 'any' type.
    credentials?,
    req?: IncomingMessage,
    sendVisitHeaders = false,
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...api.getDefaultHeaders(req, sendVisitHeaders),
      ...headerParams,
    };

    const options = {
      method: "POST",
      credentials,
      headers,
      body: data === undefined ? "{}" : JSON.stringify(data),
    };

    const root = external ? `${path}` : `${apiUrl}${path}`;

    return Promise.race([
      fetch(root, options),
      new Promise<never>((_, reject) => setTimeout(() => reject(new Error("timeout")), timeout)),
    ]);
  },
};

export const fetchDoctorsByPracticeId = (id: string) => api.get(`/hib/practices/${id}/doctors`);

export const fetchPracticeBySlug = async (slug: string) => {
  const p = await api.get(`/hib/practices/bySlug/${slug}`).catch(() => ({}));
  if (p) {
    p.doctors = await fetchDoctorsByPracticeId(p.id);

    p.insurances = await api.get(`/hib/practices/${p?.id}/visible-insurances`);

    p.virtualOnly =
      p.modalities &&
      // @ts-expect-error TS7006: Parameter 'mod' implicitly has an 'any' type.
      !p.modalities.find(mod => mod.code === "WalkIn" || mod.code === "ClinicVisit");

    if (p.virtualOnly) {
      // adding fake location
      p.locations.push({
        slug: "appointment",
        id: "appointment",
      });
    }
    return p;
  }
  return {};
};

export const fetchPracticeById = (id: string) =>
  api
    .post("/hib/practices/getForCodes", id)
    .then(response => response.json())
    .then(r => r[0]);

export const fetchDoctor = (slug: string): Promise<types.Doctor> =>
  api.get(`/hib/doctors/bySlug/${slug}`);

export const loadAvailableSlots = ({
  practiceId = PracticeId.CARBON,
  from,
  to,
  duration = 10,
  qualificationIds = defaultSlotQualificationIds,
  timezone = DEFAULT_TIMEZONE,
  limit = 1,
  enableCache = false,
  ...rest
}: {
  practiceId?: string;
  locationId?: string;
  from?: number;
  to?: number;
  duration?: number;
  virtual?: boolean;
  specialtyId?: string;
  doctorId?: string;
  qualificationIds?: string[];
  includeWalkInOnly?: boolean;
  limit?: number;
  timezone?: string;
  reasonId?: string;
  enableCache?: boolean;
}): Promise<types.ApptSlotBlock[]> => {
  if (enableCache) {
    return fetchCachedSlot({
      practiceId,
      specialtyId: rest.specialtyId,
      locationId: rest.locationId,
      doctorId: rest.doctorId,
      reasonId: rest.reasonId,
      limit,
    }).then(slot => [slot ? [slot] : []]);
  }
  const days = 7;
  return api
    .post("/hib/getNextAvailableSlots", {
      practiceId,
      from: from || Date.now(),
      to: to || (from || Date.now()) + MsMap.ONE_DAY * days,
      duration,
      timezone,
      qualificationIds,
      limit,
      ...rest,
    })
    .then(r => r.json() as ApptSlotBlock)
    .then(slots => groupBy(slots, slot => moment(slot.time).format("YYYY-MM-DD")).vals()) // group by days and get values
    .then(days => days.map(slots => slots.sortBy("time").distinctBy("time")))
    .catch(e => {
      console.error(e);
      return [];
    });
};

export const fetchReasonBySlug = memoizee(
  (slug: string, practiceId = PracticeId.CARBON): Promise<PracticeApptReason> =>
    api
      .get(`/hib/apptReasons/bySlug/${slug}?practiceIds=${practiceId}`)
      .then(response => (Array.isArray(response) && response[0]) || null),
  {promise: true},
);

export const fetchReasonsByPracticeId = (id: string) => api.get(`/hib/practices/${id}/apptReasons`);

export const fetchAllSpecialties = () => api.get("/hib/specialties/all");

export const GA = (data: GAParams): boolean | undefined => {
  if (!isBrowser()) return true;
  return !dev
    ? window && typeof window === "object" && window.ga
      ? window.ga("send", "event", data.category, data.action, data.label)
      : "no GA implemented"
    : console.log("send", "event", data.category, data.action, data.label);
};

export const getGeoDistance = (lat1: number, lng1: number, lat2: number, lng2: number) =>
  Math.round(calculateDistance(lat1, lng1, lat2, lng2));

// @ts-expect-error TS7006: Parameter 'data' implicitly has an 'any' type.
export const addToSalesforce = (csrfToken = "", data) => {
  if (dev) {
    console.log({data});
  }
  return new Promise((res, rej) => {
    api
      .post(
        "/api/salesforce",
        data,
        true,
        {
          "CSRF-Token": csrfToken,
        },
        "same-origin",
      )
      .then(response => response.json())
      .then(response => {
        if (dev) {
          console.log(response);
        }
        if (response.error) {
          return rej(response);
        }
        return res(response);
      });
  });
};

export const fetchHealthPassData = (hpToken: string): Promise<HealthPass> =>
  api.get(`/hib/healthpass/${hpToken}`, undefined, {
    "User-Agent": "CarbonHealthWeb-HealthPass",
  });

/**
 *
 * @param latitude
 * @param longitude
 * @param distance In degrees; 1 degree approximately corresponds to 111 km or 69 miles
 * Unit of measure depends on SRID set on API level
 * More info: https://www.usna.edu/Users/oceano/pguth/md_help/html/approx_equivalents.htm
 */
export const getNearbyDoctors = (
  latitude: number,
  longitude: number,
  distance: number,
): Promise<types.Doctor[]> => api.get("/hib/doctors/getNearby", {latitude, longitude, distance});

export const getLandingpageMetadata = async (
  typ: string,
  slug: string | string[],
): Promise<Metadata> => {
  try {
    return (
      (await (await api.getWithTimeout(15000, `/hib/landing-pages/${typ}/?slug=${slug}`)).json()) ||
      {}
    );
  } catch {
    return {};
  }
};

export type CovidStats = {
  avgDailyVaxCount: number;
  totalVaxCount: number;
  totalCovidTests: number;
};

export const defaultCovidStats: CovidStats = {
  avgDailyVaxCount: 0,
  totalVaxCount: 1532924, // count on Nov 1, 2021
  totalCovidTests: 1077429, // count on Nov 1, 2021
};
