if (typeof window === "undefined" && !process.env.IS_STORYBOOK) require("dotenv").config();

const getPublicEnv = () => ({
  WDYR_TRACK_ALL_PURE_COMPONENTS: process.env.WDYR_TRACK_ALL_PURE_COMPONENTS,
  CLOUDFLARELOADER_ROOT_URL: process.env.CLOUDFLARELOADER_ROOT_URL,
  FEATURE_FLAG_ENDPOINT: process.env.FEATURE_FLAG_ENDPOINT,
  CACHED_SLOTS_API_URL: process.env.CACHED_SLOTS_API_URL,
  SENTRY_ENVIRONMENT: process.env.SENTRY_ENVIRONMENT,
  disableLocalData: process.env.disableLocalData,
  PATIENT_APP_URL: process.env.PATIENT_APP_URL,
  buildEnvProd: process.env.buildEnvProd,
  CARBON_ENV: process.env.CARBON_ENV,
  DB: process.env.DB,
  CI: process.env.CI,
  GMAPS_WORKER_ROOT: process.env.GMAPS_WORKER_ROOT,
  SMAPS_WORKER_ROOT: process.env.SMAPS_WORKER_ROOT,

  /**
   * Only available in pre-prod environments
   */
  NEXT_PUBLIC_GIT_COMMIT: process.env.NEXT_PUBLIC_GIT_COMMIT,

  /**
   * Only available in prod
   */
  NEXT_PUBLIC_GIT_TAG: process.env.NEXT_PUBLIC_GIT_TAG,
});

const keyValList = Array.from(Object.entries(getPublicEnv()));

const safeParse = val => {
  try {
    return JSON.parse(val);
  } catch {
    return val;
  }
};

const reduceKeyValList = (acc, [key, val]) => ({
  ...acc,
  [`process.env.${key}`]: JSON.stringify(safeParse(val)),
});

module.exports = {
  getPublicEnv,
  webpackPlugin: keyValList.reduce(reduceKeyValList, {}),
};
