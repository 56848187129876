import tk from "timekeeper";

import {fakeTimestamp} from "../components/_common/_constants";

export function freezeTime(time = fakeTimestamp, reset = false): void {
  if (reset) {
    return tk.reset();
  }
  return tk.freeze(new Date(time));
}
