import {UAParser} from "ua-parser-js";

import {OsType, Validator} from "../utils/analytics/constants";
import {Device} from "../utils/analytics/types";

const iosRegex = /iOS/g;
const androidRegex = /Android/g;
const isIOS = (os: string) => (iosRegex.test(os) ? OsType.IOS : null);
const isAndroid = (os: string) => (androidRegex.test(os) ? OsType.Android : null);

/**
 * the `ua-parser-js` package OS strings to not always match our enum values.
 * Enum currently only contains iOS and Android. Will need to be expanded.
 * If is an exact match, return it as is. Otherwise, check for a regex match.
 * Otherwise, return `Other`
 */
const formatOsType = (os: string): OsType =>
  Validator.OsType(os) ? os : isIOS(os) || isAndroid(os) || OsType.Other;

const getOsNameAndVersion = (userAgent: string) => {
  const parser = new UAParser(userAgent);
  const {
    os: {name, version},
  } = parser.getResult();

  return {
    osType: name ? formatOsType(name) : OsType.Other,
    osVersion: version || "null",
  };
};

export const makeDevice = ({
  userAgent,
  ipAddress,
  deviceId,
  languageCode,
}: {
  userAgent: string;
  ipAddress?: string;
  deviceId: string;
  languageCode: string;
}): Device => {
  const {osType, osVersion} = getOsNameAndVersion(userAgent);

  return {
    deviceId,
    osType,
    osVersion,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    ipAddress,
    languageCode,
    userAgent,
  };
};
