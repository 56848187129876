import {useEffect} from "react";

import {actions, useTypedDispatch} from "../store";
import {onKeyHandler} from "../useEscape";

export const useSetKeyboardUser = (): void => {
  const dispatch = useTypedDispatch();

  useEffect(() => {
    // @ts-expect-error TS2322: Type 'KeyboardEventHandler<Element>' is not assignable to type 'EventListener'.
    const checkForKeyboardUser: EventListener = onKeyHandler("Tab", () => {
      dispatch(actions.setIsKeyboardUser());
      window.removeEventListener("keydown", checkForKeyboardUser);
    });

    window.addEventListener("keydown", checkForKeyboardUser);
    return () => window.removeEventListener("keydown", checkForKeyboardUser);
  }, []);
};
