import _ from "lodash";
import type {Dictionary, ObjectIterator, ValueIteratee, ValueKeyIteratee} from "lodash";

import {NonFalsy} from "./types";

export type Pred<T> = (x: T, i: number, xs: Array<T>) => boolean;

const arrayExtensions = {
  groupBy<T>(this: Array<T>, f: keyof T | ValueIteratee<T>) {
    return _.groupBy<T>(this, f);
  },
  sortBy<T>(
    this: Array<T>,
    f: ((x: T) => any) | keyof T | Array<keyof T | ((x: T) => any)>,
  ): Array<T> {
    return _.sortBy(this, f);
  },
  isEmpty<T>(this: Array<T>): boolean {
    return _.isEmpty(this);
  },
  distinct<T>(this: Array<T>): Array<T> {
    return _.uniq(this);
  },

  distinctBy<T>(this: Array<T>, f: ValueIteratee<T>): Array<T> {
    return _.uniqBy(this, f);
  },
  filterIf<T>(
    this: Array<T>,
    condition: boolean,
    predicate: (value: T, index: number, array: T[]) => unknown,
  ) {
    if (condition) {
      return this.filter(predicate);
    }
    return this;
  },
  compact<T>(this: Array<T>): Array<NonFalsy<T>> {
    return _.compact(this) as Array<NonFalsy<T>>;
  },
  toObject<T>(this: Array<[string, T]>): Record<string, T> {
    return _.fromPairs(this);
  },
  intersection<T>(this: Array<T>, a: Array<T>): Array<T> {
    return _.intersection<T>(this, a);
  },
  keep<T>(this: Array<T>, f: Pred<T>): Array<T> {
    return this.filter(f);
  },
  min<T>(this: Array<T>) {
    return _.min<T>(this);
  },
  findById<T extends {id: string}>(this: Array<T>, id: string): T | undefined {
    return this.find(x => x.id === id);
  },
  tee<T>(this: Array<T>, f: (x: T) => void): Array<T> {
    this.map(f);
    return this;
  },
  sequence<T>(this: Array<Promise<T>>): Promise<Array<T>> {
    return Promise.all(this);
  },
  mapValues<K, V1, V2>(this: Array<[K, V1]>, f: (x: V1) => V2): Array<[K, V2]> {
    return this.map(([k, v]) => [k, f(v)]);
  },
  discard<T>(this: Array<T>, f: Pred<T>) {
    return this.keep<T>((x, i, xs) => !f(x, i, xs));
  },
  isEqual(f: any) {
    return _.isEqual(this, f);
  },
  append<T>(this: Array<T>, vals: T | Array<T>) {
    if (Array.isArray(vals)) {
      this.push(...vals);
    } else {
      this.push(vals);
    }
    return this;
  },
  prepend<T>(this: Array<T>, vals: T | Array<T>) {
    if (Array.isArray(vals)) {
      this.unshift(...vals);
    } else {
      this.unshift(vals);
    }
    return this;
  },
  insertAt<T>(this: Array<T>, index: number, val: T) {
    return this.reduce((acc, curr, i) => {
      if (index === i) {
        return [...acc, val, curr];
      } else {
        return [...acc, curr];
      }
    }, [] as Array<T>);
  },
  mapPick<T extends object>(this: Array<T>, keys: Array<keyof T>) {
    return this.map(value => _.pick(value, keys));
  },
};

const objectExtensions = {
  mapValues<T extends object, TResult>(this: T, f: ObjectIterator<T, TResult>) {
    return _.mapValues<T, TResult>(this, f);
  },
  getKeys() {
    return _.keys(this);
  },
  vals<T>(this: Dictionary<T>) {
    return _.values<T>(this);
  },
  getValues() {
    return _.values(this);
  },
  crbEntries() {
    return _.entries(this);
  },
  toPairs<T>() {
    return _.toPairs<T>(this);
  },
  toArray<T>() {
    return _.toPairs<T>(this);
  },
  chPick<T>(this: T, keys: Array<keyof T>) {
    return _.pick(this, keys);
  },
  omit<T extends object>(this: T, f: keyof T | Array<keyof T>) {
    return _.omit(this, f);
  },
  pickBy<T extends object>(this: T, f?: ValueKeyIteratee<T[keyof T]>) {
    return _.pickBy(this, f);
  },
  isOEmpty() {
    return _.isEmpty(this);
  },
  assign<T>(properties: Partial<T>) {
    return {
      ...this,
      ...properties,
    };
  },
};

const stringExtensions = {
  capitalize(this: string) {
    return this.replace(/(?:^|\s)\S/g, a => a.toUpperCase());
  },
  crop(this: string, charCount: number): string {
    return this.length < charCount ? this : this.slice(0, charCount) + "...";
  },
};

Object.defineProperties(
  Array.prototype,
  _.mapValues(arrayExtensions, f => ({
    value: f,
    enumerable: false,
    writable: true,
    configurable: true,
  })),
);

Object.defineProperties(
  Object.prototype,
  _.mapValues(objectExtensions, f => ({
    value: f,
    enumerable: false,
    writable: true,
    configurable: true,
  })),
);

Object.defineProperties(
  String.prototype,
  _.mapValues(stringExtensions, f => ({
    value: f,
    enumerable: false,
    writable: true,
    configurable: true,
  })),
);

type arrayExtensions = typeof arrayExtensions;
type objectExtensions = typeof objectExtensions;
type stringExtensions = typeof stringExtensions;

declare global {
  interface Array<T> extends arrayExtensions {
    toObject<T>(): Record<string, T>;
  }
  interface ReadonlyArray<T> extends arrayExtensions {}
  interface Object extends objectExtensions {}
  interface String extends stringExtensions {}
}
