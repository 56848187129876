import client from "@services/graphql/client";

import {LocalStorageKey, getFromLocalStorage, setInLocalStorage} from "../localStorage";
import {getCurrentUserId, setCurrentUserId} from "./currentUserId";

const getTokenMap = () => getFromLocalStorage(LocalStorageKey.ApiTokenV2) || {};

const getTokenByUserId = (id: string) => getTokenMap()[id];

const setTokenMap = (tokenMap: {[userId: string]: string}) =>
  setInLocalStorage(LocalStorageKey.ApiTokenV2, tokenMap);

/**
 * Log a user in. Careful: this returns a promise, and the bugs can be very subtle
 * and hard to reproduce if you don't await it!
 */
export function login(token: string, userId: string): Promise<unknown> {
  setToken(token, userId);
  // Reset the Apollo store because some of the responses we cached may not be valid anymore.
  return client.resetStore();
}

/**
 * Set the current user's login token.
 *
 * Due to interop with the Patient app, which serves carbonhealth.com/patients and shares code
 * with the Provider app, which allows multiple simultaneous logins, we need the userId
 * as well as the token. Sad :(.
 */
export function setToken(token: string, userId: string) {
  setTokenMap({...getTokenMap(), [userId]: token});
  setCurrentUserId(userId);
}

/**
 * The user's auth token.
 *
 * ********** !!!!!!WARNING!!!!!! **********
 * This project is hosted on Vercel, which is not HIPAA-compliant. We _must not_ add this
 * token to Cookies, or send it to any APIs defined inside this project.
 *
 * All requests must be initiated from the browser, and go directly to our API or GraphQL servers.
 * ***********************************
 */
export function getToken(): string | undefined {
  const currentUserId = getCurrentUserId();
  if (currentUserId) {
    const token = getTokenByUserId(currentUserId);
    setToken(token, currentUserId);
    return token;
  }
}
