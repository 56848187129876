import {PayloadAction, createSlice} from "@reduxjs/toolkit";

import {HYDRATE} from "../index";

export type PricingTier = "tier1" | "tier2" | "tier3";

export type PricingTierItem = {
  name: string;
  price: number;
  slug: string;
  typ: string;
};

export type RegionPricing = Record<PricingTier, PricingTierItem[]>;

const initialState = {
  tier1: [],
  tier2: [],
  tier3: [],
} as RegionPricing;

export const regionPricingSlice = createSlice({
  name: "regionPricing",
  initialState,
  reducers: {
    setRegionPricing: (state, action: PayloadAction<RegionPricing>) => ({
      ...state,
      ...action.payload,
    }),
  },
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, action) => ({
      ...state,
      ...action.payload.regionPricing,
    }));
  },
});

export const {setRegionPricing} = regionPricingSlice.actions;

export default regionPricingSlice.reducer;
