import {useEffect, useState} from "react";

import {identity} from "../identity";
import {
  LocalStorageKey,
  LocalStore,
  getFromLocalStorage,
  getFromLocalStorageRaw,
} from "../localStorage";
import {safeJsonParse} from "../safeJsonParse";
import {SessionStorageKey, SessionStore, getFromSessionStorage} from "../sessionStorage";

export const useLocalStorageItem = <T extends LocalStorageKey>(key: T) =>
  useLocalStorageItemHelper(key, getFromLocalStorage, safeJsonParse);

const rawItemGetter: <T extends LocalStorageKey>(name: T) => unknown = item =>
  getFromLocalStorageRaw(item) || undefined;

export const useLocalStorageItemRaw = <T extends LocalStorageKey>(key: T) =>
  useLocalStorageItemHelper(key, rawItemGetter, identity);

export const useLocalStorageItemHelper = <T extends LocalStorageKey>(
  key: T,
  getter: (key: T) => unknown,
  parser: typeof safeJsonParse,
) => {
  const [state, setState] = useState<LocalStore[T] | undefined>();

  useEffect(() => {
    setState((getter(key) as LocalStore[T]) || undefined);
    const maybeUpdateState = (e: StorageEvent) => {
      if (e.key === key) {
        setState(parser(e.newValue) as LocalStore[T]);
      }
    };

    window.addEventListener("storage", maybeUpdateState);

    return () => {
      window.removeEventListener("storage", maybeUpdateState);
    };
  }, [key, getter, parser]);

  return state;
};

export const useSessionStorageItem = <T extends SessionStorageKey>(key: T) => {
  const [state, setState] = useState<SessionStore[T] | undefined>();

  useEffect(() => {
    setState(getFromSessionStorage(key));
    const maybeUpdateState = (e: StorageEvent) => {
      if (e.key === key) {
        setState(safeJsonParse(e.newValue) as SessionStore[T]);
      }
    };

    window.addEventListener("storage", maybeUpdateState);

    return () => {
      window.removeEventListener("storage", maybeUpdateState);
    };
  }, [key]);

  return state;
};
