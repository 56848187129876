import {IncomingMessage} from "http";

import * as Sentry from "@sentry/nextjs";
import isbot from "isbot";
import {isEmpty, omit} from "lodash";
import memoizee from "memoizee";
import {dev, isBrowser} from "src/components/_common/_constants";
import {ValueOf} from "src/types";
import {getCarbonHost, parseSearchParams} from "src/utils/urls";

import {MsMap} from "../constants/MsMap";
import {AppDispatch, actions} from "../store";
import {FeatureFlags} from "../store/slices/featureFlags";
import {isCI} from "../utils/env";
import {getPublicEnvVar} from "../utils/getEnvVar";
import tee from "../utils/promise/tee";
import {api, apiUrl} from "./api";
import {FeatureFlag} from "./featureFlagConstants";

type OverrideAttribute = {
  [key: string]: string;
};

export type FeatureFlagRequest = {
  overrideAttribute: OverrideAttribute;
  anonymous?: boolean;
  featureFlagKeys: FeatureFlag[];
};

export type FeatureFlagResponse<T> = {
  featureFlag: FeatureFlag;
  featureFlagValue: T;
  isSuccessful: boolean;
  evaluationReason: string;
};

export type FeatureFlagMap = Partial<Record<FeatureFlag, unknown>>;
type EvaluationResponse<T> = FeatureFlagResponse<ValueOf<T>>[];

export const FEATURE_FLAG_ENDPOINT = `${
  getPublicEnvVar("FEATURE_FLAG_ENDPOINT") || apiUrl
}/hib/feature-flags`;
const safeParse = (val: unknown): unknown => {
  try {
    return JSON.parse(val as string);
  } catch {
    return val;
  }
};

const getQueryOnClient = () =>
  isBrowser()
    ? parseSearchParams(new URLSearchParams(window.location.search)).mapValues(safeParse)
    : {};

/**
 * Looks through a query object for keys that match a flag in `flagsToCheck`.
 * Tries to parse the value with `JSON.parse`, on fail, return the raw value
 * Returns a map of found keys.
 * @param flagsToCheck Flags to look for in the query object
 * @param query The query object to check against
 */
export const getQueryOverrides = <T = FeatureFlagMap>(
  flagDefaultPairs: T,
  query = {} as Record<string, unknown>,
): Partial<T> => {
  const flagsToCheck =
    typeof flagDefaultPairs === "object" && flagDefaultPairs
      ? (Object.keys(flagDefaultPairs) as (keyof T)[])
      : [];
  return flagsToCheck.reduce<Partial<T>>((acc, next) => {
    const keyIsInQuery = next in query;
    const parsedValue = keyIsInQuery && safeParse(query[next as string]);
    const shouldAddOverride = keyIsInQuery && typeof parsedValue === typeof flagDefaultPairs[next];
    return shouldAddOverride ? {...acc, [next]: parsedValue} : acc;
  }, {} as Partial<T>);
};

export const getSuccessfulEvaluations = <T = FeatureFlagMap>(
  response: EvaluationResponse<T>,
): Partial<T> =>
  response
    .filter(evaluation => evaluation.isSuccessful)
    .reduce((acc, next) => ({...acc, [next.featureFlag]: next.featureFlagValue}), {} as T);

export const mergeEvalsOverridesAndDefaults = <T = FeatureFlagMap>(
  evaluations: Partial<T>,
  overrides: Partial<T>,
  defaults: T,
  featureFlagsFromRedux?: FeatureFlags,
): T => ({
  ...defaults,
  ...featureFlagsFromRedux,
  ...evaluations,
  ...overrides,
});

type FeatureFlagEvalOptions = {
  overrideAttribute?: OverrideAttribute;
  anonymous?: boolean;
  req?: IncomingMessage;
  query?: Record<string, string | string[] | undefined>;
  /**
   * FOR TESTING ONLY
   */
  localFetch?: typeof fetch;
  dispatch?: AppDispatch;
  shouldMemoize?: boolean;
  featureFlagsFromRedux?: FeatureFlagMap;
};

const fetchFeatureFlagsInner = <T extends FeatureFlagMap>(
  flagDefaultPairs: T,
  options: Partial<FeatureFlagEvalOptions> = {},
): Promise<T> => {
  const {
    overrideAttribute = {},
    anonymous = false,
    localFetch = fetch,
    dispatch,
    featureFlagsFromRedux = {},
    req,
    query = getQueryOnClient(),
  } = options;
  const userAgent = req?.headers["user-agent"];
  const isBot = userAgent ? isbot(userAgent) : false;
  // Remove flag from list if we have value in store already
  const unevaluatedDefaultFlags = omit(flagDefaultPairs, Object.keys(featureFlagsFromRedux)) as T;

  if (isEmpty(unevaluatedDefaultFlags)) {
    return Promise.resolve(featureFlagsFromRedux) as Promise<T>;
  }
  const host = isBrowser() ? window.location.host : req?.headers?.host || "";
  const env = getCarbonHost(host)?.name;
  const queryOverrides = getQueryOverrides<T>(unevaluatedDefaultFlags, query);

  if ((isBot || isCI) && !options.localFetch) {
    const evaluations = {
      ...unevaluatedDefaultFlags,
      ...queryOverrides,
    };
    dispatch?.(actions.setFeatureFlags(evaluations));
    return Promise.resolve(evaluations);
  }

  const flagsToCheck =
    typeof unevaluatedDefaultFlags === "object" && unevaluatedDefaultFlags
      ? Object.keys(unevaluatedDefaultFlags).filter(flag => !(flag in queryOverrides))
      : [];
  const featureFlagRequest = {
    overrideAttribute: {...overrideAttribute, env, isBot: JSON.stringify(isBot)},
    anonymous,
    featureFlagKeys: flagsToCheck,
  };

  const controller = new AbortController();

  const timeout = setTimeout(
    () => {
      controller.abort(new NonLoggableError("GET /hib/feature-flags timed out."));
    },
    dev ? 20000 : 3000,
  );

  const config = {
    method: "POST",
    body: JSON.stringify(featureFlagRequest),
    signal: controller.signal,
    headers: {
      ...api.getDefaultHeaders(req, true),
      "Content-Type": "application/json",
    },
  };

  return localFetch(FEATURE_FLAG_ENDPOINT, config)
    .then(catchNonLoggableErrors)
    .then(response => response.json() as Promise<EvaluationResponse<T>>)
    .then(getSuccessfulEvaluations)
    .then(evaluations =>
      mergeEvalsOverridesAndDefaults<T>(
        evaluations,
        queryOverrides,
        flagDefaultPairs,
        featureFlagsFromRedux,
      ),
    )
    .then(tee(evaluations => dispatch?.(actions.setFeatureFlags(evaluations))))
    .then(tee(result => dev && console.log("Feature flag evaluations: ", result)))
    .catch(err => {
      if (!(err instanceof NonLoggableError)) {
        Sentry.captureException(err, scope => {
          scope.setContext("Request Body", featureFlagRequest);
          scope.setContext("Feature Flags", flagDefaultPairs as Record<string, unknown>);
          scope.setContext("Request Metadata", config);
          return scope;
        });
      }
      return flagDefaultPairs;
    })
    .finally(() => {
      clearTimeout(timeout);
    });
};

const memoizedFetchFeatureFlags = memoizee(fetchFeatureFlagsInner, {
  maxAge: MsMap.ONE_MINUTE * 5, // memoize for 5 mins
  promise: true, // prevents caching bad response (exceptions)
  normalizer: args => JSON.stringify(args[0]), // use first arg as a cache key. eg: `"{[FeatureFlag.GROWTH_DISCOVERY_LOCATIONS_SECTION_1]: false,}"`
});

export const fetchFeatureFlags = <T extends FeatureFlagMap>(
  flagDefaultPairs: T,
  options: FeatureFlagEvalOptions = {},
): Promise<T> =>
  options.shouldMemoize === false || !isBrowser() // only memoize if browser. Memoizee caches results for $maxAge and doesn't invalidate on page refresh
    ? fetchFeatureFlagsInner(flagDefaultPairs, options)
    : memoizedFetchFeatureFlags(flagDefaultPairs, options);

class NonLoggableError extends Error {}

const catchNonLoggableErrors = (response: Response) => {
  if ([401, 403, 500].includes(response.status)) {
    throw new NonLoggableError(response.statusText);
  }
  return response;
};
