const {languages} = require("./src/languages.js");

const PAGES = {
  website: "rgx:^(?!/booking)(?!/confirm-identity)",
  booking: "rgx:^/booking",
  confirmIdentity: "rgx:^/confirm-identity",
};

module.exports = {
  defaultLocale: "en",
  locales: languages.map(l => l.code),
  defaultNS: "website",
  pages: {
    [PAGES.website]: ["website", "website-db"],
    [PAGES.booking]: ["web-scheduling", "website-db"],
    [PAGES.confirmIdentity]: ["confirm-identity", "website-db"],
  },
  nsSeparator: false,
  keySeparator: false,
  logBuild: process.env.NODE_ENV !== "production",
  logger: () => null,
};
