const oneMin = 1000 * 60;
const oneHour = oneMin * 60;
const oneDay = oneHour * 24;

export const MsMap = {
  ONE_MINUTE: oneMin,
  TEN_MINUTES: oneMin * 10,
  THIRTY_MINUTES: oneMin * 30,
  ONE_HOUR: oneHour,
  ONE_DAY: oneDay,
  ONE_WEEK: oneHour * 24 * 7,
  ONE_YEAR: oneDay * 365,
} as const;
